import React from 'react';
import GraphBar from './GraphBar';
import TeacherBar from './TeacherBar';
import DocumentsBar from './DocumentsBar';
import { translation } from './Translation';
import '../styles/TabBar.css';

function TabBar({ activeTab, onTabChange, teacherMessages, documents, knowledgeGraph, visited, currentRelation, use_db }) {
  const language = localStorage.getItem('language') || 'TR';

  return (
    <div className="tab-bar-container">
      <div className="tab-buttons-container">
        <button
          className={`tab-button ${activeTab === 'TeacherBar' ? 'active' : ''}`}
          onClick={() => onTabChange('TeacherBar')}
        >
        {translation[language].Teacher}
        </button>
        <button
          className={`tab-button ${activeTab === 'GraphBar' ? 'active' : ''}`}
          onClick={() => onTabChange('GraphBar')}
        >
        {translation[language].graph}
        </button>
        <button
          className={`tab-button ${activeTab === 'DocumentsBar' ? 'active' : ''}`}
          onClick={() => onTabChange('DocumentsBar')}
        >
        {translation[language].document}
        </button>
      </div>
      <div className="tab-content-container">
        {activeTab === 'TeacherBar' && <TeacherBar teacherMessages={teacherMessages} />}
        {activeTab === 'GraphBar' && (
          <div className="graph-container">
            <GraphBar 
              knowledgeGraph={knowledgeGraph} 
              visited={visited} 
              currentRelation={currentRelation} 
            />
          </div>
        )}
        {activeTab === 'DocumentsBar' && <DocumentsBar documents={documents} use_db = {use_db} />}
      </div>
    </div>
  );
}

export default TabBar;
