import React, { useState, useEffect, useRef } from 'react';
import { Search, X, FileText } from 'lucide-react';
import { ChevronDown } from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import the Toastify CSS
import '../styles/InputComponents.css';
import { translation } from './Translation';

export const languages = [
  { code: 'TR', name: 'Türkçe', flag: '/flag/tr.png' },
  { code: 'EN', name: 'English', flag: '/flag/en.png' },
  { code: 'FR', name: 'Français', flag: '/flag/fr.png' },
  { code: 'DE', name: 'Deutsch', flag: '/flag/de.png' },
  { code: 'ES', name: 'Español', flag: '/flag/es.png' },
];

const QueryInput = ({ onSubmit }) => {
  const [query, setQuery] = useState('');
  const [language, setLanguage] = useState(languages[0]); // Default to Turkish
  const [isOpen, setIsOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const fileInputRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'TR';
    const selectedLanguage = languages.find((lang) => lang.code === savedLanguage);
    setLanguage(selectedLanguage);
  }, []);

  const selectLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang.code);
    setIsOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      if (pdfFile) {
        toast.error('Only one PDF can be attached. Please remove the current PDF first.');
      } else {
        setPdfFile(file);
      }
    } else {
      toast.error('Please select a valid PDF file.');
    }
  };

  const removePdf = () => {
    setPdfFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(query, language.code, pdfFile);
  };

  return (
    <div className="query-input-container">
      <div className="background-icon"></div>
      <div className="content centered-content">
        <img 
          src={`${process.env.PUBLIC_URL}/rubber.png`} 
          alt="Rubber Duck" 
          className="header-image" 
          style={{ width: '300px', marginBottom: '20px' }} 
        />      
        <h1 style={{ marginBottom: '20px' }}>{translation[language.code].title}</h1>
        <form onSubmit={handleSubmit} className="search-form">
          <div className="input-wrapper">
            <div className="file-input-wrapper">
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={fileInputRef}
                id="pdf-upload"
                className="file-input"
              />
              <label htmlFor="pdf-upload" className="file-input-label">
                <FileText size={24} />
              </label>
            </div>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={translation[language.code].placeholder}
              className="query-input"
            />
            <div className="language-selector">
              <div className="selected-language" onClick={toggleDropdown}>
                <img src={language.flag} alt={language.name} className="flag-icon" />
                <ChevronDown size={20} />
              </div>
              {isOpen && (
                <div className="language-dropdown">
                  {languages.map((lang) => (
                    <div
                      key={lang.code}
                      className="language-option"
                      onClick={() => selectLanguage(lang)}
                    >
                      <img src={lang.flag} alt={lang.name} className="flag-icon" />
                      <span>{lang.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button type="submit" className="submit-button">
              <Search size={24} />
            </button>
          </div>
        </form>
        {pdfFile && (
          <div className="pdf-file-info">
            <FileText size={16} />
            <span>{pdfFile.name}</span>
            <button onClick={removePdf} className="remove-pdf-button">
              <X size={16} />
            </button>
          </div>
        )}
      </div>
      <ToastContainer /> 
    </div>
  );
};

const WaitingScreen = () => {
  const savedLanguage = localStorage.getItem('language') || 'TR';
  const displayLanguage = savedLanguage;

  return (
    <div className="waiting-screen">
      <div className="background-icon"></div>
      <div className="content centered-content">
        <div className="rubber-duck" style={{ marginBottom: '10px' }}>🦆</div>
        <div className="loading-circle" style={{ marginBottom: '10px' }}></div>
        <p>{translation[displayLanguage].processingMessage}</p>
      </div>
    </div>
  );
};

export { QueryInput, WaitingScreen };
