import React, { useRef, useEffect, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { forceCollide, forceManyBody, forceCenter } from 'd3-force';
import { FaCrosshairs } from 'react-icons/fa'; // Import the icon from React Icons
import '../styles/GraphBar.css';

const NodeColors = {
  VISITED: '#ADD8E6', // Softer light blue
  CURRENT: '#FFD700', // Softer orange (gold)
  DEFAULT: '#EEE8AA'  // Softer khaki (pale goldenrod)
};

function GraphBar({ knowledgeGraph, visited, currentRelation }) {
  const graphRef = useRef();
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });

  useEffect(() => {
    const processGraphData = () => {
      const nodes = new Set();
      const links = [];

      knowledgeGraph.relations.forEach(rel => {
        if (visited.includes(rel.entity1) && visited.includes(rel.entity2)) {
          nodes.add(rel.entity1);
          nodes.add(rel.entity2);
          links.push({
            source: rel.entity1,
            target: rel.entity2,
            label: rel.relation
          });
        }
      });

      return {
        nodes: Array.from(nodes).map(id => ({
          id,
          color: 
                 currentRelation.includes(id) ? NodeColors.CURRENT :
                 NodeColors.VISITED
        })),
        links
      };
    };

    setGraphData(processGraphData());
  }, [knowledgeGraph, visited, currentRelation]);

  useEffect(() => {
    if (graphRef.current) {
      // Apply a strong repulsive force initially and increase collision radius
      graphRef.current.d3Force('charge', forceManyBody().strength(-1000)); // Strong repulsion initially
      graphRef.current.d3Force('link').distance(200).strength(0.5); // Longer distance between nodes
      graphRef.current.d3Force('center', forceCenter()); // Center the graph within the canvas
      graphRef.current.d3Force('collide', forceCollide(50)); // Larger collision radius to avoid overlaps

      // Trigger a reheat of the simulation with the stronger forces
      graphRef.current.d3ReheatSimulation();
    }

    // After a short timeout, reset the forces to a normal level for stability
    const resetForcesTimeout = setTimeout(() => {
      if (graphRef.current) {
        graphRef.current.d3Force('charge').strength(-500); // Reset repulsion to normal
        graphRef.current.d3Force('link').distance(150).strength(0.5); // Reset link distance to normal
        graphRef.current.d3Force('collide').radius(30); // Reset collision radius to normal

        graphRef.current.d3ReheatSimulation(); // Reheat the simulation to apply the new forces
      }
    }, 1000); // Adjust time as needed for the desired initial effect

    return () => clearTimeout(resetForcesTimeout);
  }, [graphData]);

  const calculateFontSize = (text, radius, globalScale) => {
    let fontSize = 18 / globalScale; // Start with a base font size
    const maxFontSize = 24 / globalScale; // Set a maximum font size
    const minFontSize = 12 / globalScale; // Set a minimum font size

    // Calculate initial text width
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = `${fontSize}px Sans-Serif`;
    let textWidth = ctx.measureText(text).width;

    // Adjust font size if text is too wide or too narrow
    while (textWidth > radius * 2 && fontSize > minFontSize) {
      fontSize -= 1;
      ctx.font = `${fontSize}px Sans-Serif`;
      textWidth = ctx.measureText(text).width;
    }

    // Ensure the font size doesn't exceed the maximum
    return Math.max(minFontSize, Math.min(maxFontSize, fontSize));
  };

  const centerGraph = () => {
    if (graphRef.current) {
      graphRef.current.zoomToFit(400, 50); // Zooms and fits the graph within the container with a smooth transition
    }
  };

  return (
    <div className="graph-container">
      <button className="center-button" onClick={centerGraph}>
        <FaCrosshairs /> {/* React Icon for centralizing */}
      </button>
      <ForceGraph2D
        ref={graphRef}
        graphData={graphData}
        nodeLabel="id"
        nodeColor={node => node.color}
        nodeRelSize={8}
        linkWidth={2} // Increased link width for thicker relation lines
        linkColor={() => "#A9A9A9"} // Grey color for links
        linkLabel="label"
        linkCurvature={0} // No curvature, making lines straight
        nodeCanvasObject={(node, ctx, globalScale) => {
          const label = node.id;
          const radius = 10; // Radius of the circle
          
          // Calculate a standardized font size based on the text length
          const fontSize = calculateFontSize(label, radius, globalScale);

          // Draw the circle
          ctx.beginPath();
          ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
          ctx.fillStyle = node.color;
          ctx.fill();

          // Draw the label
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#000000'; // Black color for text
          ctx.fillText(label, node.x, node.y);
        }}
        linkCanvasObjectMode={() => 'after'}
        linkCanvasObject={(link, ctx, globalScale) => {
          const start = link.source;
          const end = link.target;

          // Draw link label directly on the line
          const label = link.label;
          const fontSize = 12 / globalScale; // Increased font size by 2 points

          // Set a minimum font size threshold
          const minFontSize = 6 / globalScale;
          const maxGlobalScale = 0.7

          // Only render the text if the font size is above the threshold
          if (fontSize > minFontSize && globalScale > maxGlobalScale) {
            ctx.font = `${fontSize}px Sans-Serif`;

            const middleX = start.x + (end.x - start.x) / 2;
            const middleY = start.y + (end.y - start.y) / 2;

            // Calculate the angle of the line
            let angle = Math.atan2(end.y - start.y, end.x - start.x);

            // Ensure the text is always upright
            if (angle > Math.PI / 2 || angle < -Math.PI / 2) {
              angle += Math.PI; // Rotate by 180 degrees if the angle is upside down
            }

            ctx.save(); // Save the current state before rotation
            ctx.translate(middleX, middleY);
            ctx.rotate(angle); // Rotate the context to align text with the line
            
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#333333'; // Dark grey, close to black, for relation text
            ctx.fillText(label, 0, 0); // Draw the text at the origin after translating
            
            ctx.restore(); // Restore the original state
          }
        }}
        d3AlphaDecay={0.03} // Slower decay for smoother transitions
        d3VelocityDecay={0.1} // Lower velocity decay for smoother movement
        warmupTicks={200} // More ticks for smoother layout
        cooldownTicks={150} // Additional ticks for stability
        onNodeDragEnd={node => {
          // Fix node position after dragging
          node.fx = node.x;
          node.fy = node.y;
        }}
        onNodeDragStart={node => {
          // Re-enable node movement when dragging starts
          node.fx = node.x;
          node.fy = node.y;
        }}
      />
    </div>
  );
}

export default GraphBar;