import React, { useState } from 'react';
import '../styles/DocumentsBar.css';
import { translation } from './Translation';

export default function DocumentsBar({ documents, use_db }) {
  const [selectedDocument, setSelectedDocument] = useState(0);
  const language = localStorage.getItem('language') || 'TR';

  return (
    <div className="documents-bar-container">
      <div className="documents-list">
        <h2>{translation[language].document}</h2>
        {documents.map((doc, index) => (
          <div
            key={index}
            className={`document-item ${selectedDocument === index ? 'selected' : ''}`}
            onClick={() => setSelectedDocument(index)}
          >
            {/* No slicing when use_db is false */}
            <span>{!use_db ? doc.title : doc.title.substring(16).slice(0, -4)}</span>
          </div>
        ))}
      </div>
      <div className="document-content">
        {selectedDocument !== null && (
          <>
            {/* No slicing when use_db is false */}
            <h3>{!use_db ? documents[selectedDocument].title : documents[selectedDocument].title.substring(16).slice(0, -4)}</h3>
            <div className="content-divider"></div>
            <p>{documents[selectedDocument].page_content}</p>
            <div className="nodes">
              <h4>Nodes:</h4>
              <div className="node-list">
                {documents[selectedDocument].nodes.map((node, index) => (
                  <span key={index} className="node-item">{node}</span>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
