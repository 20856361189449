import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../styles/TeacherBar.css';
import { translation } from './Translation';

function TeacherBar({ teacherMessages }) {
  const language = localStorage.getItem('language') || 'TR';
  return (
    <div className="teacher-bar-container">
      <h2 className="teacher-bar-title">{translation[language].teacher_message}</h2>
      <div className="teacher-messages-scroll">
        {teacherMessages.map((message, index) => (
          <div key={index} className="teacher-message-container">
            <div className="teacher-message-header">
              <i className="teacher-icon"></i>
              <span className="teacher-label">{translation[language].Teacher}</span>
            </div>
            <div className="teacher-message-content">
              <ReactMarkdown
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                      <SyntaxHighlighter
                        style={solarizedlight}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {message.data.content}
              </ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeacherBar;
