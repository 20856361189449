import React, { useState, useEffect } from 'react';
import ChatInterface from './components/ChatInterface';
import TabBar from './components/TabBar';
import { QueryInput, WaitingScreen, languages } from './components/InputComponents';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { translation } from './components/Translation';

function App() {
  const [activeTab, setActiveTab] = useState('GraphBar');
  const [appState, setAppState] = useState(() => {
    const savedState = localStorage.getItem('appState');
    return savedState || 'query';
  });
  const [appData, setAppData] = useState(() => {
    const savedData = localStorage.getItem('appData');
    return savedData ? JSON.parse(savedData) : null;
  });
  const [showConfetti, setShowConfetti] = useState(false);
  const language = localStorage.getItem('language') || 'TR';

  const { width, height } = useWindowSize();

  const getLanguageName = (code) => {
    const language = languages.find((lang) => lang.code === code);
    return language ? language.name : code;
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    localStorage.setItem('appState', appState);
  }, [appState]);

  useEffect(() => {
    if (appData) {
      localStorage.setItem('appData', JSON.stringify(appData));
      if (appData.classification && appData.classification.classification === 'OKAY') {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      }
    }
  }, [appData]);

  const handleInitialQuery = async (query, languageCode, pdfFile) => {
    setAppState('waiting');

    const languageName = getLanguageName(languageCode);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    if (pdfFile) {
      const formData = new FormData();
      formData.append('query', query);
      formData.append('language', languageName);
      formData.append('file', pdfFile);

      try {
        const response = await fetch(`${apiUrl}/initialize_doc`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        console.log(data);
        setAppData({ ...data, language: languageName });
        setAppState('chat');
      } catch (error) {
        console.error('Error fetching data:', error);
        setAppState('query');
      }
    } else {
      try {
        const response = await fetch(`${apiUrl}/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query, language: languageName }),
        });
        const data = await response.json();
        console.log(data);
        setAppData({ ...data, language: languageName });
        setAppState('chat');
      } catch (error) {
        console.error('Error fetching data:', error);
        setAppState('query');
      }
    }
  };

  const handleSendMessage = async (message, languageCode) => {
    if (!appData) return;

    let temp_lang = localStorage.getItem('language') || 'TR';
    const languageName = getLanguageName(temp_lang);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    const updatedAppData = {
      ...appData,
      user_answer: message,
      language: languageName,
    };

    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedAppData),
      });
      const data = await response.json();
      console.log('USER ANSWER: ', message);
      console.log(data);
      setAppData(data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleTerminateSession = () => {
    setAppState('query');
    setAppData(null);
    localStorage.removeItem('appData');
  };

  return (
    <Router>
      {showConfetti && <Confetti width={width} height={height} />}
      <Routes>
        <Route
          path="/query"
          element={
            appState === 'query' ? <QueryInput onSubmit={handleInitialQuery} /> : <Navigate to={`/${appState}`} />
          }
        />
        <Route path="/waiting" element={appState === 'waiting' ? <WaitingScreen /> : <Navigate to={`/${appState}`} />} />
        <Route
          path="/chat"
          element={
            appState === 'chat' && appData ? (
              <div className="app-container">
                <div className="left-section">
                  <ChatInterface
                    chatHistory={appData.all_chat_history}
                    onSendMessage={handleSendMessage}
                  />
                </div>
                <div className="right-section">
                  <TabBar
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    teacherMessages={appData.all_chat_history.filter((msg) => msg.response_metadata.agent === 'teacher')}
                    documents={appData.documents}
                    knowledgeGraph={appData.knowledge_graph}
                    visited={appData.visited}
                    currentRelation={appData.current_relation}
                    use_db={appData.use_db}
                  />
                </div>
                <button className="terminate-button" onClick={handleTerminateSession}>
                  {translation[language].terminate}
                </button>
              </div>
            ) : (
              <Navigate to="/query" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/query" />} />
      </Routes>
    </Router>
  );
}

export default App;
