import React, { useState, useRef, useEffect } from 'react';
import { Send, Mic } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import '../styles/ChatInterface.css';
import { translation } from './Translation';

const ChatInterface = ({ chatHistory, onSendMessage }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const [recordingStatus, setRecordingStatus] = useState('');
  const [frequencyData, setFrequencyData] = useState(new Array(128).fill(0));
  const [visualizerWidth, setVisualizerWidth] = useState(0);
  const language = localStorage.getItem('language') || 'TR';

  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const inputAreaRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    const formattedMessages = chatHistory.map(msg => ({
      sender: msg.type === 'human' ? 'User' :
              msg.type === 'ai' && msg.response_metadata?.agent === 'teacher' ? 'Teacher' :
              msg.type === 'ai' ? 'RubberDuck' : 'Unknown',
      content: msg.data.content
    }));
    setMessages(formattedMessages);
  }, [chatHistory]);

  useEffect(() => {
    const updateVisualizerWidth = () => {
      if (inputAreaRef.current) {
        const inputAreaWidth = inputAreaRef.current.offsetWidth;
        const buttonsWidth = 88; // Approximate width of the two buttons
        setVisualizerWidth(inputAreaWidth - buttonsWidth);
      }
    };

    updateVisualizerWidth();
    window.addEventListener('resize', updateVisualizerWidth);

    return () => {
      window.removeEventListener('resize', updateVisualizerWidth);
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const currentHeight = textarea.scrollHeight;
      textarea.style.height = `${Math.min(currentHeight - 28, 120)}px`;
    }
  };

  const handleSend = async () => {
    if (input.trim()) {
      const newMessage = { sender: 'User', content: input.trim() };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInput('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
     
      try {
        await onSendMessage(input.trim());
      } catch (error) {
        console.error('Error sending message:', error);
        setError('Failed to send message. Please try again.');
      }
    }
  };

  const startRecording = async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('getUserMedia is not supported in this browser');
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      
      const chunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(chunks, { type: 'audio/webm' });
        sendAudioToServer(audioBlob);
      };
      
      mediaRecorderRef.current.start();
      setIsRecording(true);
      setError(null);
      setRecordingStatus('Recording started');

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
      sourceRef.current.connect(analyserRef.current);

      analyserRef.current.fftSize = 512;
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const draw = () => {
        animationFrameRef.current = requestAnimationFrame(draw);
        analyserRef.current.getByteFrequencyData(dataArray);
        setFrequencyData([...dataArray]);
      };

      draw();

    } catch (error) {
      console.error('Error starting recording:', error);
      setError(`Failed to start recording: ${error.message}`);
      setIsRecording(false);
      setRecordingStatus('');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (sourceRef.current) {
        sourceRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close().catch(console.error);
      }
    }
  };

  const sendAudioToServer = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'recording.webm');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transcribe`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.error) {
        console.error('Error from server:', data.error);
        setError(`Transcription failed: ${data.error.message || 'Unknown error'}`);
      } else {
        setInput(data.transcription);
        setError(null);
      }
    } catch (error) {
      console.error('Error sending audio to server:', error);
      setError('Failed to send audio for transcription. Please try again.');
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const AudioVisualizer = () => {
    const svgHeight = 60;
    const barWidth = visualizerWidth / frequencyData.length * 3;

    return (
      <svg width={visualizerWidth} height={svgHeight} className="audio-visualizer">
        {frequencyData.map((value, index) => {
          const barHeight = (value / 255) * svgHeight;
          const x = index * barWidth;
          const y = svgHeight - barHeight;
          const hue = (index / frequencyData.length) * 360 * 3;
          return (
            <rect
              key={index}
              x={x}
              y={y}
              width={barWidth - 1}
              height={barHeight}
              fill={`hsl(${hue}, 100%, 50%)`}
            />
          );
        })}
      </svg>
    );
  };

  return (
    <div className="chat-interface">
      <h2 className="chat-title">{translation[language].chat}</h2>
      <div className="messages-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender.toLowerCase()}`}>
            <div className="message-header">
              <span className="icon">
                {message.sender === 'User' ? '👤' : message.sender === 'RubberDuck' ? '🦆' : '👨‍🏫'}
              </span>
              <strong>{translation[language][message.sender]}</strong>
            </div>
            <ReactMarkdown>{message.content}</ReactMarkdown>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-area" ref={inputAreaRef}>
        {isRecording ? (
          <AudioVisualizer />
        ) : (
          <textarea
            ref={textareaRef}
            value={input}
            onChange={handleInputChange}
            placeholder={translation[language].user_message}
            className="input-textarea"
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), handleSend())}
          />
        )}
        <button 
          onClick={toggleRecording} 
          className={`voice-button ${isRecording ? 'recording' : ''}`} 
          aria-label="Toggle voice input"
        >
          <Mic size={18} />
        </button>
        <button onClick={handleSend} className="send-button" aria-label="Send message">
          <Send size={18} />
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ChatInterface;