export const translation = {
    TR: {
      title: 'Rubber Duck AI',
      placeholder: 'Herhangi bir şey sor, daha iyi öğren...',
      processingMessage: 'Vak! Sorunuz işleniyor...',
      Teacher: "Öğretmen",
      graph: "Bilgi Grafiği",
      document: "Dosyalar",
      terminate: "Oturumu Sonlandır",
      teacher_message: "Öğretmeninin Mesajları",
      user_message: "Mesajınızı yazınız",
      chat: "Rubber Duck ile Sohbet Et",
      User: "Kullanıcı",
      RubberDuck: "Rubber Duck",
    },
    EN: {
      title: 'Rubber Duck AI',
      placeholder: 'Ask anything, learn better...',
      processingMessage: 'Quack! Processing your question...',
      Teacher: "Teacher",
      graph: "Knowledge Graph",
      document: "Documents",
      terminate: "Terminate Session",
      teacher_message: "Teacher's Messages",
      user_message: "Type your message...",
      chat: "Chat with Rubber Duck",
      User: "User",
      RubberDuck: "Rubber Duck",
    },
    FR: {
      title: 'Rubber Duck IA',
      placeholder: 'Demandez n\'importe quoi, apprenez mieux...',
      processingMessage: 'Coin! Traitement de votre question...',
      Teacher: "Enseignant",
      graph: "Graphique de Connaissances",
      document: "Documents",
      terminate: "Terminer la Session",
      teacher_message: "Messages de l'Enseignant",
      user_message: "Tapez votre message...",
      chat: "Discutez avec Rubber Duck",
      User: "Utilisateur",
      RubberDuck: "Rubber Duck",
    },
    DE: {
      title: 'Rubber Duck KI',
      placeholder: 'Frag alles, lerne besser...',
      processingMessage: 'Quak! Verarbeitung Ihrer Frage...',
      Teacher: "Lehrer",
      graph: "Wissensgraph",
      document: "Dokumente",
      terminate: "Sitzung Beenden",
      teacher_message: "Nachrichten des Lehrers",
      user_message: "Geben Sie Ihre Nachricht ein...",
      chat: "Mit Rubber Duck chatten",
      User: "Benutzer",
      RubberDuck: "Rubber Duck",
    },
    ES: {
      title: 'Rubber Duck IA',
      placeholder: 'Pregunta lo que sea, aprende mejor...',
      processingMessage: '¡Cuac! Procesando tu pregunta...',
      Teacher: "Profesor",
      graph: "Gráfico de Conocimientos",
      document: "Documentos",
      terminate: "Terminar Sesión",
      teacher_message: "Mensajes del Profesor",
      user_message: "Escribe tu mensaje...",
      chat: "Chatea con Rubber Duck",
      User: "Usuario",
      RubberDuck: "Rubber Duck",
    },
  };
  